<template>
  <WeContainer v-if="ready" card="">
    <WeCard class="mb-3 sticky-top">
      <div class="row align-items-center">
        <div class="col-12 col-md">
          <h5 class="mb-0"><i class="fas fa-scroll"></i> {{ getTitle }}</h5>
        </div>
        <div class="col-12 text-center col-md-auto">
          <div class="row align-items-center">
            <div class="col-auto p-0">
              <WeLanguageSwitcher v-model="page.lang" />
            </div>
            <div class="col pl-0">
              <!-- Submit -->
              <WeSubmitButton
                parent-class="text-center"
                v-bind:submit-status="submit"
                v-bind:update="$route.params.id"
                v-bind:permission="checkPermission"
                v-on:submit="submitForm"
              />
              <!-- ./Submit -->
            </div>
          </div>
        </div>
      </div>
    </WeCard>

    <WeCard>
      <ul class="nav nav-pills mb-4" id="pills-tab" role="tablist">
        <li class="nav-item" role="presentation">
          <a
            class="nav-link active"
            id="page-content-tab"
            data-toggle="pill"
            href="#page-content"
            role="tab"
            aria-controls="page-content"
            aria-selected="true"
            >İçerik</a
          >
        </li>
        <li class="nav-item" role="presentation">
          <a
            class="nav-link"
            id="page-seo-tab"
            data-toggle="pill"
            href="#page-seo"
            role="tab"
            aria-controls="page-seo"
            aria-selected="false"
            >SEO</a
          >
        </li>
      </ul>
      <div class="tab-content" id="pills-tabContent">
        <div
          class="tab-pane fade show active"
          id="page-content"
          role="tabpanel"
          aria-labelledby="page-content-tab"
        >
          <!-- Title -->
          <WeInput
            label="Başlık"
            placeholder="Başlık"
            v-model="form.title"
            v-bind:required="true"
            v-bind:error="$v.form.title.$error"
          />
          <!-- ./Title -->

          <!-- Slug -->
          <WeSlugCheck
            v-bind:value="form.title"
            v-bind:id="$route.params.id"
            v-bind:slug="form.slug"
            v-bind:search="true"
            v-on:slugify="changeSlug"
            type="page"
          />
          <!-- ./Slug -->
          <div class="row">
            <!-- Is Active -->
            <div class="col-auto">
              <label class="custom-label">{{ $t("status") }}</label>
              <WeSwitch
                class="mb-3"
                v-bind:show-status="true"
                v-model="form.is_active"
              />
            </div>
            <!-- ./Is Active -->

            <!-- Show Page List -->
            <div class="col-auto">
              <label class="custom-label">Sayfa Listesini Göster</label>
              <WeSwitch
                class="mb-3"
                v-bind:show-status="true"
                v-model="form.show_page_list"
              />
            </div>
            <!-- ./Show Page List -->

            <!-- Show Page Footer -->
            <div class="col-auto">
              <label class="custom-label">Alt Bilgi</label>
              <WeSwitch
                class="mb-3"
                v-bind:show-status="true"
                v-model="form.show_page_footer"
              />
            </div>
            <!-- ./Show Page Footer -->

            <!-- Show Page Footer -->
            <div class="col-auto">
              <label class="custom-label">Listede Göster</label>
              <WeSwitch
                class="mb-3"
                v-bind:show-status="true"
                v-model="form.show_page_sidebar"
              />
            </div>
            <!-- ./Show Page Footer -->
          </div>

          <!-- Content -->
          <label class="custom-label">İçerik</label>
          <WePageEditor v-model="form.contents" />
          <!-- ./Content -->
        </div>
        <div
          class="tab-pane fade"
          id="page-seo"
          role="tabpanel"
          aria-labelledby="page-seo-tab"
        >
          <!-- Meta Data -->
          <WeMetaData v-model="form.meta" />
          <!-- ./Meta Data -->
        </div>
      </div>

      <!-- Seo Bar -->
      <WeSeoBar
        v-bind:focus-keyword="form.title"
        v-bind:metaTitle="form.meta.title"
        v-bind:metaDescription="form.meta.description"
        v-bind:description="form.content"
        v-bind:slug="form.slug"
      />
      <!-- ./Seo Bar -->
    </WeCard>
  </WeContainer>
  <WeLoading v-else />
</template>
<script>
import { mapActions, mapMutations, mapState } from "vuex";
import { required } from "vuelidate/lib/validators";

export default {
  name: "Detail",
  data() {
    return {
      submit: false,
      ready: true,
      form: {
        title: null,
        slug: null,
        contents: [],
        is_active: true,
        show_page_list: true,
        show_page_footer: true,
        show_page_sidebar: true,
        meta: {
          title: null,
          keywords: null,
          description: null,
          robots: ["index", "follow"],
          canonicalUrl: null,
        },
      },
    };
  },
  methods: {
    ...mapActions("page", ["create", "update", "delete", "getById"]),
    ...mapMutations("shared", ["setMessage", "clearMessage"]),
    validateForm() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        this.$swal({
          title: "Hata",
          text: "Lütfen zorunlu alanları doldurun",
          icon: "error",
        });

        return false;
      } else {
        return true;
      }
    },
    submitForm() {
      if (!this.validateForm()) {
        return;
      }
      this.submit = true;
      if (this.$route.params.id) {
        this.updatePage();
      } else {
        this.addPage();
      }
    },
    showConfirmation(cancelButtonText, id) {
      this.$swal({
        title: "İşlem Başarılı",
        text: "Sayfalar listesine dönebilir veya bu sayfada kalabilirsiniz.",
        icon: "success",
        showCancelButton: true,
        cancelButtonText: cancelButtonText,
        confirmButtonText: "Liste Sayfasına Dön",
      }).then((confirm) => {
        if (confirm.isConfirmed) {
          this.redirectToList();
        } else {
          if (id) {
            this.$router.push(`detail/${id}`);
          } else {
            window.location.reload();
          }
        }
      });
    },
    addPage() {
      this.create({
        form: this.form,
        onSuccess: (result) => {
          if (result && result.data && result.data.status !== "error") {
            this.showConfirmation("Detaya Git", result.data.id);
          } else {
            this.showAlert(result);
          }
        },
        onFinish: () => {
          this.submit = false;
        },
      });
    },
    updatePage() {
      this.update({
        id: this.$route.params.id,
        form: this.form,
        onSuccess: (result) => {
          if (result && result.data && result.data.status !== "error") {
            this.showConfirmation("Sayfada Kal");
          } else {
            this.showAlert(result);
          }
        },
        onFinish: () => {
          this.submit = false;
        },
      });
    },
    getPageById() {
      this.ready = false;
      if (this.$route.params.id) {
        this.getById({
          id: this.$route.params.id,
          onSuccess: (result) => {
            this.form = result;
            this.ready = true;
          },
        });
      } else {
        this.ready = true;
      }
    },
    redirectToList() {
      this.$router.push("/pages");
    },
    showAlert(result, callback) {
      this.setMessage(result);
      this.$swal(this.shared.message).then((confirm) => {
        if (callback && confirm.isConfirmed) callback();
      });
      this.clearMessage();
    },
    changeSlug(data) {
      this.form.slug = data;
    },
  },
  validations: {
    form: {
      title: {
        required,
      },
      contents: {
        required,
      },
    },
  },
  computed: {
    ...mapState(["page", "shared", "session"]),
    getTitle() {
      return this.$route.params.id ? "SAYFA DÜZENLE" : "SAYFA EKLE";
    },
    checkPermission() {
      return permission.check("page", "u");
    },
  },
  mounted() {
    this.getPageById();
  },
  watch: {
    "page.lang": {
      handler: function (newVal, oldVal) {
        if (newVal !== oldVal) {
          this.ready = false;
          this.getPageById();
        }
      },
    },
  },
};
</script>
